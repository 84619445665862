var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "pt-4 px-0" },
    [
      !_vm.loaded
        ? _c("div", [
            _c("h3", { staticClass: "d-flex justify-content-center mb-3" }, [
              _vm._v("\n      Please wait while we gather your info\n    "),
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center mb-3" },
              [_c("ct-centered-spinner")],
              1
            ),
          ])
        : _c(
            "b-container",
            {
              staticClass: "p-0",
              attrs: { id: "order-confirmation-component" },
            },
            [
              !_vm.showPhoneService
                ? _c(
                    "div",
                    [
                      _c("confirmation-header", {
                        attrs: { invoice: _vm.invoice },
                        on: { logInteraction: _vm.logInteraction },
                      }),
                      _c("br"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showPhoneService ? _c("phone-service") : _vm._e(),
              _vm.showRAService
                ? _c("registered-agent-service", {
                    attrs: { services: _vm.invoice.services },
                  })
                : _vm._e(),
              _vm.orderHasRAService && _vm.orderHasNoFilingItems
                ? _c("p", [
                    _vm._v(
                      "\n      Whether you are trying to form a new company, change your existing Registered Agent,\n      or registering your company to do business in another state, we can help!\n    "
                    ),
                  ])
                : _vm._e(),
              _c("hr"),
              _vm.showSetupBizIdServices
                ? _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      _c("primary-button", {
                        attrs: { "button-text": "Continue" },
                        on: {
                          onclick: function ($event) {
                            return _vm.navigateToStage(_vm.configureDomainStage)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm.orderIsOnlyLegalDocs
                ? _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      _c("primary-button", {
                        attrs: { "button-text": "Continue to PDF Filler" },
                        on: {
                          onclick: function ($event) {
                            return _vm.navigateToFinalizeLegalDocs()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm.showButtonContainer
                ? _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      !_vm.showPhoneService
                        ? _c(
                            "button",
                            {
                              staticClass: "outline",
                              on: {
                                click: function ($event) {
                                  return _vm.printPage()
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                attrs: { type: "printer" },
                              }),
                              _vm._v("\n        Print\n      "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _vm.orderHasRAService && _vm.orderHasNoFilingItems
                            ? _c(
                                "button",
                                {
                                  staticClass: "outline",
                                  attrs: {
                                    "aria-label":
                                      _vm.homeButtonText + " button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.navigateHome()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.homeButtonText) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _vm.showPhoneService
                            ? _c("primary-button", {
                                attrs: {
                                  "aria-label": _vm.homeButtonText + " button",
                                  "button-text": _vm.homeButtonText,
                                },
                                on: {
                                  onclick: function ($event) {
                                    return _vm.navigateToPhones()
                                  },
                                },
                              })
                            : _c("primary-button", {
                                attrs: { "button-text": _vm.homeButtonText },
                                on: {
                                  onclick: function ($event) {
                                    return _vm.navigateHome()
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                      _vm.orderHasRAService && _vm.orderHasNoFilingItems
                        ? _c("primary-button", {
                            attrs: { "button-text": "Help Me With My Filing" },
                            on: {
                              onclick: function ($event) {
                                return _vm.navigateToSelectDomesticRegistration()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }